import React from 'react';

import RodoStyle from './RodoStyle';

const Rodo = ({ cookies = false }) => {

    return (
        <RodoStyle>

            {cookies &&
                <>
                    <h2>Pliki cookies</h2>
                    <p>
                        Używamy cookies i podobnych technologii m.in. w celu jak najlepszego dopasowania zawartości strony do potrzeb Użytkowników. Jeśli nie blokujesz tych plików, to zgadzasz się na ich użycie oraz zapisanie w pamięci urządzenia. Pamiętaj, że możesz samodzielnie zarządzać cookies, zmieniając ustawienia przeglądarki.
                    </p>
                </>
            }
            
            <h2>Informacja Rodo</h2>
            <p>
                W związku z rozpoczęciem stosowania z dniem 25 maja 2018 r. Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
                o ochronie danych) (dalej Rozporządzenie 2016/679), informujemy, iż od dnia 25 maja 2018 r. będą Pani/Panu przysługiwały określone poniżej prawa związane z przetwarzaniem
                Pani/Pana danych osobowych przez <strong>TRIO COLOR Daniel Sztajer</strong>
            </p>

            <p>
                Jednocześnie na podstawie art. 13 Rozporządzenia 2016/679 informujemy, iż od dnia 25 maja
                2018 r. aktualne będą poniższe informacje i zasady, związane z przetwarzaniem Pani/Pana
                danych osobowych przez <strong>TRIO COLOR Daniel Sztajer</strong>
            </p>

            <ol>
                <li>Administratorem Pani/Pana danych osobowych jest TRIO COLOR DANIEL SZTAJER , przetwarzającym jest biuro rachunkowe.</li>
                <li>Pani/Pana dane osobowe będą przetwarzane:
                    <ol>
                        <li>w celu wykonania umowy na podstawie art. 6 ust. 1 lit b ogólnego rozporządzenia o ochronie danych osobowych nr 2016/679 (Rozporządzenie 2016/679), a w zakresie w jakim podanie danych jest fakultatywne – na podstawie wyrażonej przez Panią/Pana zgody (art. 6 ust. 1 lit a Rozporządzenia 2016/679;</li>
                        <li>dla celów wypełnienia obowiązków prawnych ciążących na Firmie  na podstawie powszechnie obowiązujących przepisów prawa:
                            <ul>
                                <li>w zakresie danych osobowych pracowników, współpracowników, podwykonawców czy kontrahentów;</li>
                                <li>w zakresie przetwarzania powierzonych danych osobowych jedynie w zakresie i celu realizacji umowy;</li>
                                <li>w zakresie przetwarzania powierzonych danych do dnia wygaśnięcia lub rozwiązania umowy, po ustaniu której Usługi Księgowo-Rachunkowe zobowiązuje się do usunięcia (lub zwrotu) powierzonych mu danych, w terminie 14 dni, ze wszystkich nośników, programów, aplikacji i kopii, chyba, że obowiązek ich dalszego przetwarzania wynika z odrębnych przepisów prawa;</li>
                                <li>w zakresie zobowiązania do zachowania poufności przez wszystkie osoby upoważnione do przetwarzania danych osobowych;</li>
                                <li>w zakresie zobowiązania się Firmy  do udzielania pomocy w wywiązywaniu się z obowiązków, wynikających z art. 32-36 RODO;</li>
                                <li>w zakresie stosowania ochrony powierzonych mu danych osobowych przed niedozwolonym lub niezgodnym z prawem przetwarzaniem, tj. zniszczeniem, utraceniem, modyfikowaniem, nieuprawnionym ujawnieniem lub dostępem do danych przesyłanych, przechowywanych lub w inny sposób przetwarzanych oraz przypadkową utratą, zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub organizacyjnych (integralność i poufność);</li>
                                <li>w zakresie stosowania środków technicznych i organizacyjnych, określonych w art. 32 RODO adekwatnych do stwierdzonego ryzyka naruszenia praw lub wolności powierzonych danych osobowych.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                    
                <li>Pani/Pana dane osobowe w zakresie numeru REGON i PKD zostały pozyskane z publicznie dostępnych rejestrów.</li>
                <li>
                    Pani/Pana dane będą przekazywane:
                    <ul>
                        <li>dostawcom systemów informatycznych i usług IT;</li>
                        <li>podmiotom świadczącym na rzecz Firmy . umowy, takie jak: dochodzenie należności, usługi prawne, usługi księgowe , usługi kadrowo-płacowe;</li>
                        <li>operatorom pocztowym i kurierom;</li>
                        <li>bankom w zakresie realizacji płatności;</li>
                        <li>organom uprawnionym do otrzymania Pani/Pana danych na podstawie przepisów prawa.</li>
                    </ul>
                </li>
                <li>
                    Pani/Pana dane osobowe będą przetwarzane przez okres trwania umowy, lecz nie dłużej niż przez okres trwania umowy( jeżeli była zawarta). Okres przetwarzania danych osobowych może zostać każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych będzie niezbędne dla dochodzenia ewentualnych roszczeń lub obrony przed takimi roszczeniami przez Firmę TRIO COLOR Daniel Sztajer.<br />
                    Po tym okresie dane będą przetwarzane jedynie w zakresie i przez czas wymagany przepisami prawa, w tym przepisami o rachunkowości.
                </li>
                <li>
                    Przysługuje Pani/Panu prawo: dostępu do treści danych oraz żądania ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu względem przetwarzania danych.
                </li>
                <li>
                    Przysługuje Pani/Panu także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, gdy uzna Pani/Pan, że przetwarzanie Pani/Pana danych osobowych narusza przepisy rozporządzenia 2016/679.
                </li>
                <li>
                    Pani/Pana dane osobowe nie będą podlegały profilowaniu.
                </li>
                <li>
                    Podanie danych osobowych jest wymagane przez Firmę TRIO COLOR Daniel Sztajer w celu zawarcia i wykonania umowy, z wyjątkiem danych osobowych, oznaczonych jako fakultatywne, których podanie jest dobrowolne. Konsekwencją niepodania danych osobowych wymaganych przez Firmę            TRIO COLOR Daniel Sztajer  jest brak możliwości zawarcia i wykonania umowy.
                </li>
            </ol>

            <div className="signature"><strong>25 maja 2018 r.</strong></div>
            <br /><br />


        </RodoStyle>
    );
}
export default Rodo;