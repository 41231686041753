import React from 'react';
import HeaderRateStyle from '../Header/HeaderStyle';
import logo from '../../assets/images/sprzedaj-toner-logo.png';
import { FaPhoneAlt , FaFacebook , FaTwitter , FaGooglePlusG , FaBars , FaUser as IcoUser } from 'react-icons/fa';
import { phone } from '../../actions/variables';
import User from '../Header/User/User';

const Header = ({ dataMenu , setMm , setShowForm , userData , setShowLogin , userLogout , slideTo , refTop }) => {

    const handleClickHamburger = event => {
        event.preventDefault();
        setMm(true);
    }

    const handleClickMenu = (event,obj) => {
        event.preventDefault();
        if (obj.slide !== undefined){
            slideTo(obj.slide);
        } else if (obj.express!==undefined) {
            setShowForm(1);
        }
    }

    const logout = event => {
        event.preventDefault();
        userLogout();
    }

    const handleLogoClick = event => {
        event.preventDefault();
        slideTo('top');
    }

    return (
        <HeaderRateStyle className="headerMain">
            <div className="top" ref={refTop}>
                <div className="contentWidth">
                    <h1>Skup pełnych oryginalnych tonerów w Warszawie</h1>
                    <div className="right">
                        <ul className="main">
                            <li className="phone"><a href="tel:111 2222 333"><FaPhoneAlt className="icon" /> {phone}</a></li>
                            <li className="mail"><a href="mailto:info@sprzedajtoner.pl">info@sprzedajtoner.pl</a></li>
                        </ul>
                        <div className="userPlace">
                            {userData === null &&
                                <a href="" className="login" title="Zaloguj się" onClick={ev => {
                                    ev.preventDefault();
                                    setShowLogin(true);
                                }}><IcoUser /></a>
                            }
                            {userData !== null && <User userData={userData} logout={logout} />}
                        </div>
                    </div>

                </div>
            </div>
            <div className="bottom">
                <div className="contentWidth">
                    <a className="logo" href="/"><img src={logo} alt="Sprzedaj toner" /></a>
                </div>
            </div>
        </HeaderRateStyle>
    );
}
export default Header;