import styled from 'styled-components';

const RodoStyle = styled.div`
    
    padding: 10px 30px; 
    h1{
        font-size: 30px;
        font-style: italic;
        border-bottom: 1px dashed grey;
    }
    h2{
        font-size: 24px;
        color: #556b23;
    }
    strong{
        font-weight: 600;
    }
`;	

export default RodoStyle;